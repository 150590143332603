@font-face {
  font-family: 'Energy';
  src: url('../assets/fonts/EnergyLight.woff') format('woff');
  font-weight: 200;
  font-display: block;
}

@font-face {
  font-family: 'Energy';
  src: url('../assets/fonts/Energy.woff') format('woff');
  font-display: block;
  font-weight: 400;
}

@font-face {
  font-family: 'Energy';
  src: url('../assets/fonts/EnergyBold.woff') format('woff');
  font-display: block;
  font-weight: 700;
}
