@import './font.css';

html {
  font-size: 10px;
}

body {
  margin: 0;
  font-family: 'Energy', sans-serif !important;
}

.hidden {
  display: none !important;
}

.bold {
  font-weight: bold;
}
